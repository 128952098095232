/**
 * Gibt für das Rennen eine ID zurück, welche innerhalb einer URL benutzt werden kann.
 */
import {Rennen} from "./veranstaltung";

export function idFuerRennen(rennen: Rennen): string {
    // Der Dateiname ist eindeutig und kann somit als ID dienen.
    // Um die URL etwas leserlicher zu gestalten wird die Dateiextension (.json) entfernt.
    return rennen.dateiname.slice(0, -5);
}

/**
 * Ermittelt den Dateinamen eines Rennens auf Basis dessen ID.
 */
export function dateinameAusRennId(id: string): string {
    return id + '.json';
}
