import React from "react";
import {Link, Route} from "react-router-dom";
import {idFuerRennen} from "../domain/routing";
import {Rennen} from "../domain/veranstaltung";

type RennlisteProps = {
    /** Liste der darzustellenden Rennen. */
    rennen: Rennen[]
    /** Gibt an ob eine Header Zeile, vor der Liste der Rennen, angezeigt werden soll. */
    mitHeader: boolean
}

/**
 * Komponente welche alle Rennen der Veranstaltung auflistet.
 *
 * Wird ein Rennen ausgewählt, wird die entsprechende Route aktiviert.
 */
function Rennliste(props: RennlisteProps) {

    const menuItems = props.rennen.map(r => {
        const id = idFuerRennen(r);
        const href = '/' + id;
        return (
            <Route key={id} path={href} children={({match}) => (
                <li className={'nav-item ' + (match ? 'active' : '')}>
                    <Link to={href}>{r.name}</Link>
                </li>
            )}/>
        );
    });

    return (
        <ul className="nav">
            {props.mitHeader && <li className="divider" data-content="Alle Rennen der Veranstaltung"/>}
            {menuItems}
        </ul>
    );
}

export default Rennliste;
