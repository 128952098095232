import axios, {AxiosPromise} from "axios";
import React, {ReactNode, useEffect, useState} from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import {Veranstaltung} from "./domain/veranstaltung";
import Header from "./layout/Header";
import LoadingResource from "./layout/LoadingResource";
import MainMenu from "./layout/MainMenu";
import OffCanvasLayout from "./layout/OffCanvasLayout";
import Renninfo from "./rennen/Renninfo";
import Logos from "./veranstaltung/Logos";
import Veranstaltungsinfo from "./veranstaltung/Veranstaltungsinfo";

/**
 * Hauptkomponente der Live-Anzeige.
 *
 * Hier wird die Veranstaltung geladen und anschließend das Layout, sowie der Router konfiguriert.
 */
function App() {

    const [ladevorgangFuerVeranstaltung, setLadevorgangFuerVeranstaltung] = useState<Promise<any> | undefined>(undefined);
    const [veranstaltung, setVeranstaltung] = useState<Veranstaltung | undefined>(undefined);

    useEffect(() => {
        setLadevorgangFuerVeranstaltung(
            ladeVeranstaltung().then(v => setVeranstaltung(v.data))
        )
    }, []);

    return (
        // Es wird der HashRouter verwendet, um möglichst wenig Anforderungen an den ausliefernden Webserver zu stellen.
        // Die Nutzung von BrowserRouter würde erfordern, dass jede Route mit der index.html beantwortet wird.
        <HashRouter>
            <LoadingResource promise={ladevorgangFuerVeranstaltung}
                             errorMessage="Die Veranstaltungsinformationen konnten nicht geladen werden.">
                {veranstaltung && appLayout(veranstaltung)}
            </LoadingResource>
        </HashRouter>
    );
}

function ladeVeranstaltung(): AxiosPromise<Veranstaltung> {
    // Es wird erwartet, dass WinCanoe die Datei mit dem definierten Namen, im selben Ordner wie die index.html ablegt.
    return axios.get('./veranstaltung.json');
}

function appLayout(veranstaltung: Veranstaltung): ReactNode {

    const sidebar = <MainMenu veranstaltung={veranstaltung}/>;

    const header = <Header/>;

    const main = <main>
        <Switch>
            <Route exact path="/">
                <Veranstaltungsinfo veranstaltung={veranstaltung}/>
            </Route>
            <Route path="/:id_rennen">
                <Renninfo/>
            </Route>
        </Switch>
    </main>;

    const footer = <footer className="mt-2">
        <Logos logos={veranstaltung.logos}/>
    </footer>;

    return <OffCanvasLayout sidebar={sidebar}
                            header={header}
                            main={main}
                            footer={footer}/>
}

export default App;
