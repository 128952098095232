import React from "react";
import {Veranstaltung} from "../domain/veranstaltung";
import Rennliste from "./Rennliste";

import "./Veranstaltungsinfo.scss"

type VeranstaltungsinfoProps = {
    /** Die darzustellende Veranstaltung. */
    veranstaltung: Veranstaltung
}

/**
 * Komponente welche Informationen zur Veranstaltung anzeigt.
 */
function Veranstaltungsinfo(props: VeranstaltungsinfoProps) {

    const veranstaltungsname = props.veranstaltung.name;

    const veranstaltungsbeginn = isoToGermanDateFormat(props.veranstaltung.beginn);
    const veranstaltungsende = isoToGermanDateFormat(props.veranstaltung.ende);

    const rennInfo = props.veranstaltung.rennen.length === 0 ?
        <p className="text-warning">Für die Veranstaltung sind noch keine Rennen definiert.</p>
        :
        <nav>
            <Rennliste rennen={props.veranstaltung.rennen} mitHeader={true}/>
        </nav>;

    return (
        <div>

            <h2 className="float-left veranstaltungsinfo-name">{veranstaltungsname}</h2>

            <div className="clearfix"/>

            <div>
                <span className="chip">
                    <i className="las la-calendar la-lg mr-1"/>
                    {veranstaltungsbeginn} bis {veranstaltungsende}
                </span>
            </div>

            <div className="mt-2">
                {rennInfo}
            </div>
        </div>
    );
}

function isoToGermanDateFormat(date: string) {
    return new Date(date).toLocaleDateString('de-DE');
}

export default Veranstaltungsinfo;
