import axios, {AxiosPromise} from "axios";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Renndetails} from "../domain/renndetails";
import {dateinameAusRennId} from "../domain/routing";
import LoadingResource from "../layout/LoadingResource";
import Rennergebnisse from "./Rennergebnisse";

/**
 * Komponente welche Informationen zu einem Rennen anzeigt.
 */
function Renninfo() {

    const {id_rennen} = useParams();
    const dateiname = dateinameAusRennId(id_rennen || '');

    const [ladevorgangFuerRenndetails, setLadevorgangRenndetails] = useState<Promise<any> | undefined>(undefined);
    const [renndetails, setRenndetails] = useState<Renndetails | undefined>(undefined);

    useEffect(() => {
        setLadevorgangRenndetails(
            ladeRenndetails(dateiname).then(v => setRenndetails(v.data))
        )
    }, [dateiname]);

    return (
        <LoadingResource promise={ladevorgangFuerRenndetails}
                         errorMessage="Die Ergebnisse des Rennens konnten nicht geladen werden.">
            {renndetails && ergebnisse(renndetails)}
        </LoadingResource>
    );
}

function ladeRenndetails(dateiname: string): AxiosPromise<Renndetails> {
    return axios.get(dateiname);
}

function ergebnisse(renndetails: Renndetails) {
    return <>
        <h3>
            <i className="las la-trophy la-lg mr-1"/>
            {renndetails.name}
        </h3>
        <Rennergebnisse ergebnisse={renndetails.ergebnisse}/>
    </>
}

export default Renninfo;
