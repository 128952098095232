import React from "react";
import packageJson from '../../package.json';

import "./Header.scss"

/**
 * Header Komponente.
 */
function Header() {

    const appName = 'Live-Anzeige';
    const appVersion = packageJson.version;

    return (
        <header className="navbar">
            <section className="navbar-section">
                <h1 className="header-app-name">{appName}</h1>
                <span className="header-app-version" title="Softwareversion">{appVersion}</span>
            </section>
        </header>
    );
}

export default Header;
