import React from "react";
import {Link} from "react-router-dom";
import {Veranstaltung} from "../domain/veranstaltung";
import Rennliste from "../veranstaltung/Rennliste";

import "./MainMenu.scss"

type MainMenuProps = {
    /** Veranstaltung für welche die Navigation aufgebaut wird. */
    veranstaltung: Veranstaltung
}

/**
 * Komponente welche das Hauptmenü darstellt.
 */
function MainMenu(props: MainMenuProps) {

    const {name, rennen} = props.veranstaltung;

    return (
        <nav>
            <h2 className="m-2">
                <Link to={'/'} className="main-menu-veranstaltungsname">{name}</Link>
            </h2>
            <Rennliste rennen={rennen} mitHeader={false}/>
        </nav>
    );
}

export default MainMenu;
